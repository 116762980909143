import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { FacebookProvider, Comments } from "react-facebook"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { previous, next } = this.props.pageContext
    // post = Object.assign({}, post, post.fields, post.frontmatter)

    return (
      <Layout>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          postData={post}
          pageContext={this.props.pageContext}
        />
        <section className="blog_area single-post-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 posts-list">
                <div className="single-post row">
                  <div className="feature-img mb-3 col-lg-12">
                    {post.frontmatter.featuredImage && (
                      <Img
                        sizes={
                          post.frontmatter.featuredImage.childImageSharp.sizes
                        }
                      />
                    )}
                  </div>
                  <div className="col-md-3">
                    <div className="blog_info d-flex justify-content-end">
                      <div className="blog_meta list d-flex flex-column">
                        <div className="blog_meta_item">
                          <span className="d-flex justify-content-end">
                            {post.frontmatter.author}
                            <i className="lnr lnr-user" />
                          </span>
                        </div>
                        <div className="blog_meta_item">
                          <span className="d-flex justify-content-end">
                            {post.frontmatter.date}
                            <i className="lnr lnr-calendar-full" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9 blog_details">
                    <h2>{post.frontmatter.title}</h2>
                    <section dangerouslySetInnerHTML={{ __html: post.html }} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <FacebookProvider appId="481804642356984">
                      <Comments
                        href={`https://blog.belmontrunners.com/${post.frontmatter.id}`}
                        width="100%"
                      />
                    </FacebookProvider>
                  </div>
                </div>
                <div className="navigation-area">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 nav-left flex-row d-flex justify-content-start align-items-center">
                      {previous && (
                        <div className="detials">
                          <p>Prev Post</p>
                          <Link to={previous.fields.slug} rel="prev">
                            <h4>{previous.frontmatter.title}</h4>
                          </Link>
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6 col-md-6 col-12 nav-right flex-row d-flex justify-content-end align-items-center">
                      {next && (
                        <div className="detials">
                          <p>Next Post</p>
                          <Link to={next.fields.slug} rel="next">
                            <h4>{next.frontmatter.title}</h4>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        id
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
